
import { defineComponent } from 'vue';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { mapState, mapActions } from 'vuex';

// components
import { DmAsyncContent, DmSwitch, DmTile, DmSelect } from '@dobrymechanik/vue-ui';

import { URI_SLOT_FINDER_REPAIR_REASONS_GET, URI_SLOT_FINDER_REPAIR_REASONS_EDIT, URI_SLOT_FINDER_REPAIR_REASONS } from '@/api/endpoints';

interface RepairReason {
  id: string;
  text: string;
  selected?: boolean;
}

interface RepairReasonGroup {
  id: string;
  text: string;
  items: RepairReason[];
  delay: number;
}

interface Delay {
  text: string;
  key: number;
}

export default defineComponent({
  name: 'ServicesSetting',
  components: {
    DmSwitch,
    DmTile,
    DmAsyncContent,
    DmSelect
  },
  props: {

  },
  emits: [],
  data () {
    return {
      isLoading: false as boolean,
      repairReasons: [] as RepairReasonGroup[],
      repairReasonsFiltered: [] as RepairReasonGroup[],
      delays: [
        { text: 'brak', key: 0 },
        { text: '1 h', key: 60 },
        { text: '2 h', key: 120 },
        { text: '4 h', key: 240 },
        { text: '8 h', key: 480 },
        { text: '24 h', key: 1440 },
        { text: '2 dni', key: 2880 },
        { text: '3 dni', key: 4320 },
        { text: '5 dni', key: 7200 },
        { text: '7 dni', key: 10080 }
      ] as Delay[]
    };
  },
  computed: {
    ...mapState('garage', {
      garageId: 'id'
    }),
    repairReasonsPayload (): RepairReasonGroup[] {
      return this.repairReasonsFiltered.map((c: RepairReasonGroup) => {
        return {
          ...c,
          items: c.items.filter((i: RepairReason) => i.selected).map((m: RepairReason) => {
            return {
              id: m.id,
              text: m.text
            };
          })
        };
      });
    }
  },
  created () {
    this.fetchRepairReasons();
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    async fetchRepairReasons (): Promise<void> {
      this.isLoading = true;
      try {
        const response = (await this.$http.get<RepairReasonGroup[]>(URI_SLOT_FINDER_REPAIR_REASONS)).data;
        this.repairReasons = response;
        this.fetchRepairReasonsByGarage();
      } catch {
        this.isLoading = false;
        this.createNotification({
          message: this.$t('common.tryAgain'),
          type: 'error'
        });
      }
    },
    async fetchRepairReasonsByGarage (): Promise<void> {
      try {
        const response = (await this.$http.get<RepairReasonGroup[]>(URI_SLOT_FINDER_REPAIR_REASONS_GET(this.garageId))).data;
        this.repairReasonsFiltered = this.repairReasons
          .map((d: RepairReasonGroup) => {
            const element = response.find((r: RepairReasonGroup) => r.id === d.id);
            return {
              ...d,
              delay: element?.delay || 0,
              items: d.items.map((i: RepairReason) => {
                return {
                  ...i,
                  selected: !!element?.items.find((x: RepairReason) => x.id === i.id)
                };
              })
            };
          });
      } catch {
        this.createNotification({
          message: this.$t('common.tryAgain'),
          type: 'error'
        });
      } finally {
        this.isLoading = false;
      }
    },
    async changeRepairReasons (): Promise<void> {
      this.isLoading = true;
      try {
        await this.$http.put<any>(URI_SLOT_FINDER_REPAIR_REASONS_EDIT(this.garageId), { specializations: this.repairReasonsPayload });
      } catch {
        this.createNotification({
          message: this.$t('common.tryAgain'),
          type: 'error'
        });
      } finally {
        this.fetchRepairReasonsByGarage();
        this.isLoading = false;
      }
    }
  }
});

