import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withKeys as _withKeys, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "workspaces__tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_DmCheckbox = _resolveComponent("DmCheckbox")
  const _component_Workspace = _resolveComponent("Workspace")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_DmTile = _resolveComponent("DmTile")
  const _component_WorkspaceModal = _resolveComponent("WorkspaceModal")
  const _component_ServicesSetting = _resolveComponent("ServicesSetting")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({}),
    "data-test": "workspaces-page"
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({ e: 'navigation' })
      }, [
        _createVNode("h2", {
          class: [_ctx.$bem({ e: 'title' }), "has-margin-bottom-lg"]
        }, _toDisplayString(_ctx.$t(`app.header.${_ctx.activeTab}`)), 3),
        _createVNode("div", _hoisted_1, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock("div", {
              key: `view-type:${tab}`,
              tabindex: "0",
              class: ["workspaces__tab", { 'workspaces__tab--selected': _ctx.activeTab === tab }],
              onClick: ($event: any) => (_ctx.activeTab = tab),
              onKeyup: _withKeys(($event: any) => (_ctx.activeTab = tab), ["enter"])
            }, _toDisplayString(_ctx.$t(`app.header.${tab}`)), 43, ["onClick", "onKeyup"]))
          }), 128))
        ])
      ], 2),
      (_ctx.activeTab === 'workspaces')
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_DmTile, {
              color: "white",
              padding: "md",
              rounded: "",
              bordered: ""
            }, {
              default: _withCtx(() => [
                _createVNode("div", {
                  class: _ctx.$bem({ e: 'header' })
                }, [
                  _createVNode(_component_DmIconButton, {
                    icon: "plus",
                    color: "primary",
                    dark: "",
                    rounded: "",
                    class: "has-margin-left-lg",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.creatingNewWorkspace = true))
                  }),
                  _createVNode(_component_DmCheckbox, {
                    id: "show-inactive-workspaces",
                    modelValue: _ctx.areInactiveWorkspacesShown,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.areInactiveWorkspacesShown = $event)),
                    class: "has-margin-left-lg has-margin-right-lg",
                    rounded: "",
                    label: _ctx.$t('settings.workspaces.showInactive')
                  }, null, 8, ["modelValue", "label"])
                ], 2),
                _createVNode(_component_DmAsyncContent, {
                  loading: _ctx.isLoading,
                  error: _ctx.isError,
                  reloadable: "",
                  class: "has-margin-bottom-sm",
                  onReload: _ctx.fetchWorkspaces
                }, {
                  default: _withCtx(() => [
                    _createVNode("div", {
                      class: ["workspaces__wrapper", { 'workspaces__wrapper--blur': _ctx.editMode }]
                    }, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.mappedWorkspaces, (workspace) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_Workspace, {
                          key: workspace.id,
                          loading: false,
                          class: _ctx.$bem({ e: 'workspace' }),
                          workspace: workspace,
                          "onEditMode:update": ($event: any) => (_ctx.setEditedWorkspace(workspace.id)),
                          "onWorkspace:update": _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateWorkspace($event))),
                          "onWorkspace:status": _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeWorkspaceStatus($event)))
                        }, null, 8, ["class", "workspace", "onEditMode:update"])), [
                          [_vShow, !workspace.deleted || _ctx.areInactiveWorkspacesShown]
                        ])
                      }), 128))
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["loading", "error", "onReload"])
              ]),
              _: 1
            }),
            (_ctx.editedWorkspace || _ctx.creatingNewWorkspace)
              ? (_openBlock(), _createBlock(_component_WorkspaceModal, {
                  key: 0,
                  loading: _ctx.modalLoading,
                  "create-mode": _ctx.creatingNewWorkspace,
                  data: _ctx.creatingNewWorkspace ? _ctx.createNewWorkspace() : _ctx.editedWorkspace,
                  "onWorkspace:update": _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateWorkspace($event))),
                  "onWorkspace:create": _cache[6] || (_cache[6] = ($event: any) => (_ctx.createWorkspace($event))),
                  onClose: _ctx.closeModal
                }, null, 8, ["loading", "create-mode", "data", "onClose"]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_ServicesSetting, { key: 1 }))
    ]),
    _: 1
  }, 8, ["class"]))
}