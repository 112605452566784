export enum StatusOrder {
    NEW = 1,
    RECEIVED = 2,
    REPAIRED = 3,
    RETURNED = 4
}

export interface CalendarReorderedRepairs {
    reorderedRepairsIds: string[];
}

export type StatusOrderValue = 1 | 2 | 3 | 4;

export interface SettingsWorkspaceVisibility {
    day: number;
    isExternallyVisible: boolean;
    externallyVisibleTimeStart: string | null;
    externallyVisibleTimeEnd: string | null;
}

export interface CalendarResource {
    id: string;
    title: string;
    position: number;
    visibility: SettingsWorkspaceVisibility[];
    externalConfig?: any;
}

export interface CalendarEvent {
    id: string;
    resourceId: string | null;
    start: string | null;
    end: string | null;
    reason: string;
    notes: string | null;
    statusOrder: StatusOrderValue;
    backgroundColor?: string;
    draggable: boolean;
    editable?: boolean;
    startEditable?: boolean;
    resourceEditable?: boolean;
    brand: string | null;
    model: string | null;
    licensePlateNumber?: string;
    vin: string | null;
    name: string | null;
    phone: string;
    email: string;
    workspace: string | null;
    disabled?: boolean;
    hidden?: boolean;
    price?: number | null;
    duration?: number;
    isExternal?: boolean;
    positionInWorkspace?: number;
}

export interface CalendarEventDetailsStatus {
    statusId: string;
    name: string;
    order: StatusOrderValue;
    disabled: boolean;
    confirm: boolean;
    info: string | null;
    send: string | null;
}

export interface CalendarEventDetails {
    id: string;
    reason: string;
    reasonId?: string | null;
    notes: string | null;
    plannedRepairAt: string;
    clientId: string;
    name: string;
    phone: string;
    email: string | null;
    engine: string | null;
    carId: string;
    brand: string;
    brandId?: string | null;
    duration: number;
    model: string;
    modelId?: string | null;
    isExternal?: boolean;
    licensePlateNumber: string;
    vin: string | null;
    activeStatusName: string;
    activeStatusOrder: StatusOrderValue;
    workspace: string | null;
    editable: boolean;
    cancelable: boolean;
    draggable: boolean;
    pdf: boolean;
    availableStatuses: CalendarEventDetailsStatus[];
    price?: number | null;
    hasPartialData?: boolean;
    marketingConsent: boolean | null;
    workspaceId?: string;
    tecDocMeta: {
        brandName: string | null;
        modelName: string | null;
        typeName: string | null;
        brandId: string | number | null;
        modelId: string | number | null;
        typeId: string | number | null;
        year: number | string | null;
    };
}

export interface CalendarCancellationReason {
    id: string;
    name: string;
    displayAfterRepairPlanedDate: boolean;
}

export interface CalendarSearchResultCar {
    id: string;
    brand: string;
    brandId?: string | null;
    model: string;
    modelId?: string | null;
    licensePlateNumber: string;
    vin: string | null;
}
export interface CalendarSearchResult {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string | null;
    cars: CalendarSearchResultCar[];
}
