
import { defineComponent } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton
} from '@/components';

// interfaces
import { WorkspaceType } from '@/api/models';
import { WorkspaceWorkingHours, WorkspaceSlot } from '@/models/workspaces';

// components
import { DmCheckbox, DmSelect, DmSwitch, DmInput } from '@dobrymechanik/vue-ui';

// functions
import { createWorkingHoursSlots, endTimeIsNotLessThanStartTime } from '@/helpers/functions';

export default defineComponent({
  name: 'WorkspaceModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppModalButton,
    AppModalBody,
    DmCheckbox,
    DmSelect,
    DmSwitch,
    DmInput
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    createMode: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close',
    'workspace:update',
    'workspace:create'
  ],

  data: function () {
    return {
      workspace: JSON.parse(JSON.stringify(this.data)),
      quantityFrom: '' as string,
      quantityTo: '' as string
    };
  },
  computed: {
    unableToApplyChanges (): boolean {
      if (!this.workspace.name) return true;

      if (this.workspace.type === WorkspaceType.Scheduled && this.workspace.visible) {
        const visibleElements = this.workspace.externalConfig.visibility
          .filter((el: any) => el.visible);
        const isError = visibleElements
          .find((el: any) => !el.from || !el.to || !endTimeIsNotLessThanStartTime(el.from, el.to));
        const selectedCategories = this.workspace.externalConfig.specializationCategories
          .filter((el: any) => el.selected);
        if (isError || !visibleElements.length || !selectedCategories.length) return true;
      }

      if (this.workspace.type === WorkspaceType.Quantitative && this.workspace.visible) {
        const visibleElements = this.workspace.externalConfig.admissionHours
          .filter((el: any) => el.visible);
        const selectedCategories = this.workspace.externalConfig.specializationCategories
          .filter((el: any) => el.selected);
        if (!this.quantityFrom || !this.quantityTo || !this.workspace.externalConfig.capacity || !visibleElements.length || !selectedCategories.length) return true;
        if (!endTimeIsNotLessThanStartTime(this.quantityFrom, this.quantityTo)) return true;
      }

      return false;
    },
    workingHoursSlotsScheduled (): WorkspaceWorkingHours[] {
      const startTime = '00:00:00';
      const endTime = '23:30:00';
      const slot = WorkspaceSlot.ThirtyMin;
      return createWorkingHoursSlots(startTime, endTime, slot);
    },
    workingHoursSlotsQuantity (): WorkspaceWorkingHours[] {
      const startTime = '07:00:00';
      const endTime = '19:00:00';
      const slot = WorkspaceSlot.ThirtyMin;
      return createWorkingHoursSlots(startTime, endTime, slot);
    },
    visitsArray () {
      return new Array(10).fill(undefined).map((item, index) => {
        return {
          key: index + 1,
          text: index + 1
        };
      });
    }
  },
  mounted () {
    const admissionHour = this.findAdmissionHour();
    if (admissionHour) {
      this.quantityFrom = admissionHour.from;
      this.quantityTo = admissionHour.to;
    }
  },
  methods: {
    findAdmissionHour () {
      return this.data.externalConfig.admissionHours?.find((h: any) => h.from && h.to);
    },
    close (): void {
      this.$emit('close');
    },
    getDay (day: number): any {
      switch (day) {
      case 7: return this.$t('days.sunday');
      case 1: return this.$t('days.monday');
      case 2: return this.$t('days.tuesday');
      case 3: return this.$t('days.wednesday');
      case 4: return this.$t('days.thursday');
      case 5: return this.$t('days.friday');
      case 6: return this.$t('days.saturday');
      }
    },
    getDayShortcut (day: number): any {
      switch (day) {
      case 7: return this.$t('daysShortcuts.sunday');
      case 1: return this.$t('daysShortcuts.monday');
      case 2: return this.$t('daysShortcuts.tuesday');
      case 3: return this.$t('daysShortcuts.wednesday');
      case 4: return this.$t('daysShortcuts.thursday');
      case 5: return this.$t('daysShortcuts.friday');
      case 6: return this.$t('daysShortcuts.saturday');
      }
    },
    mapWorkspacePayload (data: any, quantitative: boolean): any {
      return data.filter((el: any) => el.visible).map((el: any) => {
        return {
          day: el.day,
          to: quantitative ? this.quantityTo : el.to,
          from: quantitative ? this.quantityFrom : el.from
        };
      }).filter((el: any) => el.to && el.from);
    },
    filterSpecCategories (workspace: any) {
      return workspace.externalConfig.specializationCategories.filter((c: any) => !!c.types.find((t: string) => t === workspace.type));
    },
    submitForm (): void {
      if (this.createMode) this.addWorkspace();
      else this.applyChanges();
    },
    applyChanges (): void {
      let payload: any = {
        id: this.workspace.id,
        type: this.workspace.type,
        name: this.workspace.name,
        position: this.workspace.position,
        deleted: this.workspace.deleted
      };

      const visibility = this.mapWorkspacePayload(this.workspace.externalConfig.visibility, false);
      const admissionHours = this.mapWorkspacePayload(this.workspace.externalConfig.admissionHours, true);
      const specializationCategories = this.workspace.externalConfig.specializationCategories
        .filter((c: any) => c.selected && c.types.includes(this.workspace.type))
        .map((c: any) => c.key);

      if (this.workspace.type === WorkspaceType.Scheduled && this.workspace.visible && visibility.length) {
        payload = {
          ...payload,
          externalConfig: {
            visibility: visibility,
            specializationCategories: specializationCategories
          }
        };
      } else if (this.workspace.type === WorkspaceType.Quantitative && this.workspace.visible && admissionHours.length) {
        payload = {
          ...payload,
          externalConfig: {
            admissionHours: admissionHours,
            capacity: this.workspace.externalConfig.capacity,
            specializationCategories: specializationCategories
          }
        };
      }
      this.$emit('workspace:update', payload);
    },
    addWorkspace (): void {
      let payload: any = {
        type: this.workspace.type,
        name: this.workspace.name
      };

      const visibility = this.mapWorkspacePayload(this.workspace.externalConfig.visibility, false);
      const admissionHours = this.mapWorkspacePayload(this.workspace.externalConfig.admissionHours, true);
      const specializationCategories = this.workspace.externalConfig.specializationCategories
        .filter((c: any) => c.selected && c.types.includes(this.workspace.type))
        .map((c: any) => c.key);

      if (this.workspace.type === WorkspaceType.Scheduled && this.workspace.visible && visibility.length) {
        payload = {
          ...payload,
          externalConfig: {
            visibility: visibility
          },
          specializationCategories: specializationCategories
        };
      } else if (this.workspace.type === WorkspaceType.Quantitative && this.workspace.visible && admissionHours.length) {
        payload = {
          ...payload,
          externalConfig: {
            admissionHours: admissionHours,
            capacity: this.workspace.externalConfig.capacity
          },
          specializationCategories: specializationCategories
        };
      }
      this.$emit('workspace:create', payload);
    }
  }
});
