import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "workspace__header" }
const _hoisted_2 = { class: "has-margin-xs" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "d-flex has-margin-left-md align-center" }
const _hoisted_5 = { class: "has-margin-sm" }
const _hoisted_6 = { class: "d-flex has-margin-xs align-center" }
const _hoisted_7 = { class: "has-margin-sm" }
const _hoisted_8 = { class: "workspace__scheduled-days" }
const _hoisted_9 = { class: "d-flex align-center" }
const _hoisted_10 = { class: "workspace__scheduled-categories" }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "d-flex" }
const _hoisted_13 = { class: "workspace__quantity-categories" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_DmIcon = _resolveComponent("DmIcon")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")

  return (_ctx.workspace)
    ? (_openBlock(), _createBlock(_component_DmAsyncContent, {
        key: 0,
        loading: _ctx.loading,
        class: "workspace"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'name' })
            }, [
              _createVNode("h4", _hoisted_2, _toDisplayString(_ctx.workspace.name), 1)
            ], 2),
            _createVNode("div", _hoisted_3, [
              _createVNode(_component_DmIconButton, {
                icon: "edit",
                color: "primary",
                dark: "",
                rounded: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('editMode:update')))
              }),
              _createVNode(_component_DmIconButton, {
                icon: _ctx.workspace.deleted ? 'trash-restore' : 'trash',
                "icon-type": _ctx.workspace.deleted ? 'fa' : null,
                "icon-prefix": _ctx.workspace.deleted ? 'fa-' : null,
                color: _ctx.workspace.deleted ? 'success' : 'error',
                dark: "",
                rounded: "",
                loading: _ctx.workspace.isStatusChanging,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('workspace:status', _ctx.workspace)))
              }, null, 8, ["icon", "icon-type", "icon-prefix", "color", "loading"])
            ])
          ]),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'type' })
          }, [
            _createVNode("div", _hoisted_4, [
              _createVNode(_component_DmIcon, {
                name: _ctx.workspace.type === 'quantitative' ? 'wrench' : 'timer',
                size: "lg"
              }, null, 8, ["name"]),
              _createVNode("span", _hoisted_5, _toDisplayString(_ctx.workspaceType), 1)
            ])
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'visible' })
          }, [
            _createVNode("div", null, [
              _createVNode("div", _hoisted_6, [
                _createVNode(_component_DmIcon, {
                  class: _ctx.workspace.visible ? _ctx.$bem({ e: 'visible-icon' }) : _ctx.$bem({ e: 'visible-icon--invisible' }),
                  name: "wifi",
                  size: "lg"
                }, null, 8, ["class"]),
                _createVNode("span", _hoisted_7, _toDisplayString(_ctx.workspace.visible
              ? _ctx.$t('settings.workspaces.visibleOutside')
              : _ctx.$t('settings.workspaces.invisibleOutside')), 1)
              ])
            ])
          ], 2),
          (_ctx.workspace.type === 'scheduled' && _ctx.workspace.visible)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: _ctx.$bem({ e: 'scheduled' })
              }, [
                _createVNode("div", _hoisted_8, [
                  _createVNode("div", {
                    class: _ctx.$bem({ e: 'label' })
                  }, " Dni przyjęć: ", 2),
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.workspace.externalConfig.visibility, (day) => {
                    return (_openBlock(), _createBlock("div", {
                      key: `${day.day}`,
                      class: _ctx.$bem({ e: 'scheduled-day' })
                    }, [
                      _createVNode("div", _hoisted_9, [
                        _createVNode("div", {
                          class: _ctx.$bem({ e: 'day-label' })
                        }, _toDisplayString(_ctx.getDay(day.day)), 3),
                        _createVNode("div", null, _toDisplayString(_ctx.createScheduledBusinessHours(day.from, day.to)), 1)
                      ])
                    ], 2))
                  }), 128))
                ]),
                _createVNode("div", _hoisted_10, [
                  _createVNode("div", {
                    class: _ctx.$bem({ e: 'label' })
                  }, " Kategorie usług: ", 2),
                  _createVNode("div", {
                    class: _ctx.$bem({ e: 'value' })
                  }, [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filterSpecCategories(_ctx.workspace), (category) => {
                      return (_openBlock(), _createBlock("div", {
                        key: `${category.key}`,
                        class: "d-flex align-center has-margin-bottom-md"
                      }, [
                        _createVNode("div", {
                          class: _ctx.$bem({ e: 'day-label--md' })
                        }, _toDisplayString(category.text), 3),
                        (category.selected)
                          ? (_openBlock(), _createBlock(_component_DmIcon, {
                              key: 0,
                              name: "check",
                              size: "lg"
                            }))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ], 2)
                ])
              ], 2))
            : (_ctx.workspace.visible)
              ? (_openBlock(), _createBlock("div", {
                  key: 1,
                  class: _ctx.$bem({ e: 'quantity' })
                }, [
                  _createVNode("div", _hoisted_11, [
                    _createVNode("div", {
                      class: _ctx.$bem({ e: 'quantity-hours' })
                    }, [
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'label' })
                      }, " Przyjęcia w godzinach: ", 2),
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'value' })
                      }, _toDisplayString(_ctx.createQuantitiveBusinessHours(_ctx.workspace)), 3)
                    ], 2),
                    _createVNode("div", {
                      class: _ctx.$bem({ e: 'quantity-slots' })
                    }, [
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'label' })
                      }, " Liczba wizyt: ", 2),
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'value' })
                      }, _toDisplayString(_ctx.workspace.externalConfig.capacity), 3)
                    ], 2)
                  ]),
                  _createVNode("div", _hoisted_12, [
                    _createVNode("div", {
                      class: _ctx.$bem({ e: 'quantity-days-wrapper' })
                    }, [
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'label' })
                      }, " Dni przyjęć: ", 2),
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'value' })
                      }, [
                        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.workspace.externalConfig.admissionHours, (day) => {
                          return (_openBlock(), _createBlock("div", {
                            key: `${day.day}`,
                            class: "d-flex align-center has-margin-bottom-md"
                          }, [
                            _createVNode("div", {
                              class: _ctx.$bem({ e: 'day-label--md' })
                            }, _toDisplayString(_ctx.getDay(day.day)), 3),
                            (day.visible)
                              ? (_openBlock(), _createBlock(_component_DmIcon, {
                                  key: 0,
                                  name: "check",
                                  size: "lg"
                                }))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ], 2)
                    ], 2),
                    _createVNode("div", _hoisted_13, [
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'label' })
                      }, " Kategorie usług: ", 2),
                      _createVNode("div", {
                        class: _ctx.$bem({ e: 'value' })
                      }, [
                        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filterSpecCategories(_ctx.workspace), (category) => {
                          return (_openBlock(), _createBlock("div", {
                            key: `${category.key}`,
                            class: "d-flex align-center has-margin-bottom-md"
                          }, [
                            _createVNode("div", {
                              class: _ctx.$bem({ e: 'day-label--md' })
                            }, _toDisplayString(category.text), 3),
                            (category.selected)
                              ? (_openBlock(), _createBlock(_component_DmIcon, {
                                  key: 0,
                                  name: "check",
                                  size: "lg"
                                }))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ], 2)
                    ])
                  ])
                ], 2))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading"]))
    : _createCommentVNode("", true)
}