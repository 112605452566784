export interface WorkspaceVisibility {
  day: number;
  from: string;
  to: string;
}

export enum WorkspaceType {
  Scheduled = 'scheduled',
  Quantitative = 'quantitative'
}

export interface WorkspaceExternalConfigScheduled {
  visibility: WorkspaceVisibility[];
  specializationCategories: string[];
}

export interface WorkspaceExternalConfigQuantitative {
  admissionHours: WorkspaceVisibility[];
  capacity: number;
  specializationCategories: string[];
}

export interface SettingsWorkspace {
  id: string;
  type: WorkspaceType;
  name: string;
  position: number;
  deleted: boolean;
  externalConfig: WorkspaceExternalConfigScheduled | WorkspaceExternalConfigQuantitative | null;
}

export interface SettingsGarage {
  billingEmail: string;
  billingPhone: string;
  companyAddressAddress: string;
  companyAddressCity: string;
  companyAddressName: string;
  companyAddressPostalCode: string;
  customerServiceEmail: string;
  customerServicePhone: string;
  garageAddressAddress: string;
  garageAddressCity: string;
  garageAddressName: string;
  garageAddressPostalCode: string;
  id: string;
  nip: string;
  logo?: string;
}
