
import { defineComponent } from 'vue';

// interfaces
import { WorkspaceType } from '@/api/models';

// components
import { DmAsyncContent, DmIcon, DmIconButton } from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'Workspace',
  components: {
    DmIcon,
    DmIconButton,
    DmAsyncContent
  },
  props: {
    workspace: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'editMode:update',
    'workspace:update',
    'workspace:status'
  ],
  computed: {
    workspaceType (): string {
      switch (this.workspace.type) {
      case WorkspaceType.Scheduled:
        return this.$t('settings.workspaces.scheduledWorkspace');
      case WorkspaceType.Quantitative:
        return this.$t('settings.workspaces.quantityWorkspace');
      default:
        return '';
      }
    }
  },
  methods: {
    getDay (day: number): any {
      switch (day) {
      case 7: return this.$t('days.sunday');
      case 1: return this.$t('days.monday');
      case 2: return this.$t('days.tuesday');
      case 3: return this.$t('days.wednesday');
      case 4: return this.$t('days.thursday');
      case 5: return this.$t('days.friday');
      case 6: return this.$t('days.saturday');
      }
    },
    splitHourString (hourString: string): string {
      const splitted = hourString.split(':');
      return `${splitted[0]}:${splitted[1]}`;
    },
    createQuantitiveBusinessHours (workspace: any): string {
      if (workspace.type === 'quantitative') {
        const admissionHour = workspace.externalConfig.admissionHours.find((h: any) => h.from && h.to);
        return `${this.splitHourString(admissionHour.from)} - ${this.splitHourString(admissionHour.to)}`;
      }
      return '-';
    },
    createScheduledBusinessHours (from: string, to: string): string {
      if (from && to) {
        return `${this.splitHourString(from)} - ${this.splitHourString(to)}`;
      }
      return '-';
    },
    filterSpecCategories (workspace: any) {
      return workspace.externalConfig.specializationCategories.filter((c: any) => !!c.types.find((t: string) => t === workspace.type));
    }
  }
});

