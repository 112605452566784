
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';
import { Workspace, WorkspaceModal, ServicesSetting } from './_components';
import {
  DmTile,
  DmAsyncContent,
  DmCheckbox,
  DmIconButton
} from '@dobrymechanik/vue-ui';
import { URI_WORKSPACES, URI_WORKSPACE, URI_SLOT_FINDER_REPAIR_AUTOBOOKABLE_CATEGORIES } from '@/api/endpoints';
import { SettingsWorkspace } from '@/api/models';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import {
  FETCH_REPAIR_REASONS
} from '@/store/repair/actions';

export default defineComponent({
  name: 'Workspaces',
  components: {
    AppWrapper,
    DmTile,
    DmAsyncContent,
    DmCheckbox,
    DmIconButton,
    Workspace,
    WorkspaceModal,
    ServicesSetting
  },
  data () {
    return {
      isLoading: true as boolean,
      isError: false as boolean,
      workspaces: [] as SettingsWorkspace[],
      areInactiveWorkspacesShown: false as boolean,
      editMode: false as boolean,
      editedWorkspaceId: null as null | string,
      newWorkspace: '' as string,
      isNewWorkspaceLoading: false as boolean,
      isEditWorkspaceModalShown: true as boolean,
      modalLoading: false as boolean,
      creatingNewWorkspace: false as boolean,
      tabs: ['workspaces', 'services'] as string[],
      activeTab: 'workspaces' as string,
      autobookableCategories: [] as any[]
    };
  },
  computed: {
    mappedWorkspaces (): any[] {
      const initialVisibility = this.createInitialVisibility();
      const initialSpecializationCategories = this.autobookableCategories;
      return this.workspaces
        .map((w: any) => {
          let workspace = {
            ...w,
            isSaving: false,
            visible: !!w.externalConfig || false
          };

          if (w.type === 'quantitative') {
            workspace = {
              ...workspace,
              externalConfig: {
                ...w.externalConfig,
                specializationCategories: w.externalConfig ? initialSpecializationCategories.map(c => {
                  const duplicate = w.externalConfig.specializationCategories.find((el: any) => el === c.key);
                  return duplicate ? { ...c, selected: true } : { ...c };
                }) : initialSpecializationCategories,
                admissionHours: w.externalConfig ? initialVisibility.map(iv => {
                  const duplicate = w.externalConfig.admissionHours.find((v: any) => v.day === iv.day);
                  return duplicate ? { ...duplicate, visible: true } : { ...iv };
                }) : initialVisibility,
                visibility: initialVisibility
              }
            };
          } else {
            workspace = {
              ...workspace,
              externalConfig: {
                ...w.externalConfig,
                specializationCategories: w.externalConfig ? initialSpecializationCategories.map(c => {
                  const duplicate = w.externalConfig.specializationCategories.find((el: any) => el === c.key);
                  return duplicate ? { ...c, selected: true } : { ...c };
                }) : initialSpecializationCategories,
                visibility: w.externalConfig ? initialVisibility.map(iv => {
                  const duplicate = w.externalConfig.visibility.find((v: any) => v.day === iv.day);
                  return duplicate ? { ...duplicate, visible: true } : { ...iv };
                }) : initialVisibility,
                admissionHours: initialVisibility,
                capacity: 0
              }
            };
          }
          return workspace;
        });
    },
    editedWorkspace (): any {
      return this.mappedWorkspaces.find(w => w.id === this.editedWorkspaceId);
    }
  },
  created () {
    this.fetchWorkspaces();
    this.fetchRepairReasons(this.$http);
    this.fetchAutobookableCategories();
  },
  methods: {
    ...mapActions('repair', {
      fetchRepairReasons: FETCH_REPAIR_REASONS
    }),
    createNewWorkspace () {
      return {
        externalConfig: {
          visibility: this.createInitialVisibility(),
          specializationCategories: this.autobookableCategories,
          admissionHours: this.createInitialVisibility(),
          capacity: 0
        },
        name: '',
        type: 'quantitative',
        visible: false
      };
    },
    setEditedWorkspace (editedWorkspaceId: string): void {
      this.editedWorkspaceId = editedWorkspaceId;
      this.isEditWorkspaceModalShown = !this.isEditWorkspaceModalShown;
    },
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    async fetchWorkspaces (): Promise<void> {
      this.isLoading = true;
      this.isError = false;

      try {
        const response = (await this.$http.get<any[]>(URI_WORKSPACES)).data;

        this.workspaces = response;
      } catch (e) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    createInitialVisibility (): any[] {
      return new Array(7).fill(null).map((el, index) => {
        return {
          day: index + 1,
          to: '',
          from: '',
          visible: false
        };
      });
    },
    async fetchAutobookableCategories (): Promise<void> {
      this.isLoading = true;
      this.isError = false;

      try {
        const response = (await this.$http.get<any[]>(URI_SLOT_FINDER_REPAIR_AUTOBOOKABLE_CATEGORIES)).data;
        this.autobookableCategories = response.map((item) => {
          return {
            key: item.id,
            text: item.text,
            selected: false,
            types: item.supportedWorkspaceTypes
          };
        });
      } catch (e) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    editModeToggle (value: boolean) {
      this.editMode = value;
    },
    async updateWorkspace (workspace: any): Promise<void> {
      this.editedWorkspaceId = workspace.id;
      this.modalLoading = true;
      try {
        const { data } = await this.$http.put<SettingsWorkspace>(URI_WORKSPACE(workspace.id), workspace);

        this.workspaces = this.workspaces.map(el => {
          return workspace.id === el.id ? data : el;
        });
      } catch (e) {
        this.createNotification({
          message: this.$t('settings.workspaces.editFailed'),
          type: 'error'
        });
      } finally {
        this.modalLoading = false;
        this.closeModal();
      }
    },
    async changeWorkspaceStatus (workspace: SettingsWorkspace): Promise<void> {
      const deleted = !workspace.deleted;
      try {
        const payload = {
          deleted: deleted,
          type: workspace.type,
          name: workspace.name,
          position: workspace.position
        };

        const { data } = await this.$http.put<SettingsWorkspace>(URI_WORKSPACE(workspace.id), payload);

        this.workspaces = this.workspaces.map(el => {
          return workspace.id === el.id ? data : el;
        });
      } catch (e) {
        this.createNotification({
          message: this.$t('settings.workspaces.statusChangeFailed'),
          type: 'error'
        });
      }
    },
    async createWorkspace (workspace: any): Promise<void> {
      this.modalLoading = true;
      const payload = {
        ...workspace,
        position: this.workspaces.length + 1
      };
      try {
        const { data } = await this.$http.post<SettingsWorkspace>(URI_WORKSPACES, payload);
        this.workspaces.push(data);
      } catch (e) {
        this.createNotification({
          message: this.$t('settings.workspaces.addFailed'),
          type: 'error'
        });
      } finally {
        this.modalLoading = false;
        this.closeModal();
      }
    },
    closeModal (): void {
      this.editedWorkspaceId = null;
      this.creatingNewWorkspace = false;
    }
  }
});
